import React, { Suspense, lazy } from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

import Can from '@/components/Can';
import { Spinner } from '@/components/Layout';
import { Dashboard } from '@/components/Dashboard';
import { ChecklistEI } from '@/components/views/Reports/ChecklistEI';
import { ChecklistEHS } from '@/components/views/Reports/ChecklistEHS';
import { ErgonomicWorkAnalysis } from '@/components/views/ErgonomicAnalysisWork';
import { ErgonomicAnalysisWorkCreate } from '@/components/views/ErgonomicAnalysisWork/Create';

import { Files } from '../components/views/Files';
import { Management } from '../components/Management';
import { ActionPlans } from '../components/ActionPlans';
import { Appointment } from '../components/Appointment';
import { MtmReport } from '@/components/views/MtmReport';
import { HealthMatrix } from '../components/HealthMatrix';
import { NewAppointment } from '../components/NewAppointment';
import { AnalyticsDashboard } from '../components/views/AnalyticsDashboard';
import { Account, ManageUsers, Preferences } from '@/components/Settings';

// import { ComplaintsAndCertificates } from '../components/ComplaintsAndCertificates';

const Upload = lazy(() => import('@/views/Upload'));
const Complaints = lazy(() => import('@/components/views/Complaints'));
const Certificates = lazy(() => import('@/components/views/Certificates'));
const MtmReportList = lazy(() => import('@/components/views/MtmReportList'));
const ChronoAnalysis = lazy(() => import('@/components/views/ChronoAnalysis'));
const ManageOrganizations = lazy(() => import('@/components/Settings/ManageOrganizations'));
const ChronoAnalysisReports = lazy(() => import('@/components/views/ChronoAnalysisReports'));

export function PrivateRoutes() {
	const checklistEIMatch = useRouteMatch('/reporting/checklist-ei');
	const checklistEHSMatch = useRouteMatch('/reporting/checklist-ehs');

	return (
		<Route
			component={() => (
				<Suspense fallback={<Spinner />}>
					{Can('ehs') && (
						<>
							<Route exact path="/ehs/" component={Dashboard} />
							<Route exact path="/ehs/action_plan" component={ActionPlans} />
							<Route exact path="/ehs/appointment" component={AnalyticsDashboard} />
							<Route exact path="/ehs/reporting/aet" component={ErgonomicWorkAnalysis} />
							<Route exact path="/ehs/reporting/aet/create" component={ErgonomicAnalysisWorkCreate} />
							<Route exact path="/ehs/complaints" component={Complaints} />
							<Route exact path="/ehs/certificates" component={Certificates} />
							<Route exact path="/ehs/complaints/:companyId/:sectorId/:lineId" component={Complaints} />
							<Route
								exact
								path="/ehs/certificates/:companyId/:sectorId/:lineId"
								component={Certificates}
							/>
							<Route
								exact
								path="/ehs/reporting/checklist-ehs/:file_id/:organization_id/:company_id"
								component={ChecklistEHS}
							/>
							{Can('management', 'workers') && (
								<>
									<Route
										exact
										component={Management}
										path="/ehs/workers/:type/:companyId/:sectorId/:lineId"
									/>
									<Route exact path="/ehs/workers" component={Management} />
									<Route exact path="/ehs/detailed" component={Appointment} />
									<Route exact path="/ehs/health-matrix" component={HealthMatrix} />
									<Route exact path="/ehs/appointment_dashboard" component={NewAppointment} />
								</>
							)}
						</>
					)}
					{Can('engineering') && (
						<>
							<Route
								component={ChecklistEI}
								path="/engineering/reporting/checklist-ei/:file_id/:organization_id/:company_id"
							/>
							<Route exact component={ChronoAnalysis} path="/engineering/chrono-analysis/report" />
							<Route
								exact
								component={ChronoAnalysis}
								path="/engineering/chrono-analysis/report/:chrono_analysis_report_id"
							/>
							<Route exact component={ChronoAnalysisReports} path="/engineering/chrono-analysis" />
							<Route exact component={MtmReportList} path="/engineering/mtm" />
							<Route exact component={MtmReport} path="/engineering/mtm/report/:id?" />
						</>
					)}
					<Route exact path="/files" component={Files} />
					<Route exact path="/upload" component={Upload} />
					<Route exact path="/settings/account" component={Account} />
					{Can('settings', 'management') && (
						<>
							<Route exact path="/settings/preferences" component={Preferences} />
							<Route exact path="/settings/manage-users" component={ManageUsers} />
							<Route exact path="/settings/companies" component={ManageOrganizations} />
						</>
					)}
					{checklistEIMatch?.isExact || (checklistEHSMatch?.isExact && <Redirect to="/reporting" />)}
				</Suspense>
			)}
		/>
	);
}
