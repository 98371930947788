import React from 'react';
import { Menu } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import type { MenuInfo } from 'rc-menu/lib/interface';

import { Title } from './Title';
import { EiIcon } from './icons/Ei';
import { CrIcon } from './icons/CR';
import { MtmIcon } from './icons/Mtm';
import Can from '@/components/Can';
import { LogoMenu, SubMenuCentered } from './styles';

interface SideMenuProps {
	selectKey: string;
}

const URL_LOGO = 'https://kinebot-statics.s3.amazonaws.com/logo.png';

export function SideMenu({ selectKey }: Readonly<SideMenuProps>) {
	const history = useHistory();

	function handleOnClick({ key }: MenuInfo): void {
		history.push(key);
	}

	return (
		<>
			<LogoMenu src={URL_LOGO} />
			<Menu
				theme="light"
				mode="inline"
				onClick={handleOnClick}
				selectedKeys={[selectKey]}
				defaultSelectedKeys={[selectKey]}
			>
				{Can('ehs') && (
					<Menu.Item key="/ehs/">
						<Icons.BarChartOutlined />
						<Title>Dashboard</Title>
					</Menu.Item>
				)}
				{Can('engineering') && (
					<Menu.Item key="/engineering/">
						<Icons.VideoCameraOutlined />
						<Title>Dashboard</Title>
					</Menu.Item>
				)}
				<Menu.Item key="/upload">
					<Icons.CloudUploadOutlined />
					<Title>Upload</Title>
				</Menu.Item>
				<Menu.Item key="/files">
					<Icons.FolderOpenOutlined />
					<Title>All files</Title>
				</Menu.Item>
				{Can('engineering') && (
					<SubMenuCentered icon={<EiIcon />} title={<Title>Manufacturing Engineering</Title>}>
						<Menu.Item key="/engineering/chrono-analysis">
							<CrIcon style={{ marginRight: '10px' }} />
							<Title>Chronoanalysis</Title>
						</Menu.Item>
						<Menu.Item key="/engineering/mtm">
							<MtmIcon style={{ marginRight: '10px' }} />
							<Title>MTM</Title>
						</Menu.Item>
					</SubMenuCentered>
				)}
				{Can('ehs') && (
					<Menu.SubMenu icon={<Icons.ToolOutlined />} title={<Title>EHS</Title>}>
						<Menu.Item key="/ehs/reporting/aet">
							<Icons.FileDoneOutlined />
							<Title>EAW</Title>
						</Menu.Item>
						<Menu.Item key="/ehs/action_plan">
							<Icons.ReconciliationOutlined />
							<Title>Action plans</Title>
						</Menu.Item>
						<Menu.SubMenu icon={<Icons.ToolOutlined />} title={<Title>Management</Title>}>
							<Menu.Item key="/ehs/appointment">
								<Icons.BarChartOutlined />
								<Title>Appointment</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/detailed">
								<Icons.LineChartOutlined />
								<Title>Detailed</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/workers">
								<Icons.UserOutlined />
								<Title>Workers</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/health-matrix">
								<Icons.MedicineBoxOutlined />
								<Title>Health Matrix</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/complaints">
								<Icons.TableOutlined />
								<Title>{I18n.get('Complaints')}</Title>
							</Menu.Item>
							<Menu.Item key="/ehs/certificates">
								<Icons.DiffOutlined />
								<Title>{I18n.get('Medical Certificates')}</Title>
							</Menu.Item>
						</Menu.SubMenu>
					</Menu.SubMenu>
				)}
				<Menu.SubMenu title={<Title>Settings</Title>} icon={<Icons.SettingOutlined />}>
					<Menu.Item key="/settings/account">
						<Icons.InfoCircleOutlined />
						<Title>My account</Title>
					</Menu.Item>
					{(Can('ehs') || Can('engineering')) && (
						<>
							{Can('settings', 'preferences') && Can('ehs') && (
								<Menu.Item key="/settings/preferences">
									<Icons.ControlOutlined />
									<Title>Preferences</Title>
								</Menu.Item>
							)}
							{Can('settings', 'management') && (
								<>
									<Menu.Item key="/settings/manage-users">
										<Icons.TeamOutlined />
										<Title>Manage users</Title>
									</Menu.Item>
									<Menu.Item key="/settings/companies">
										<Icons.PartitionOutlined />
										<Title>Manage companies</Title>
									</Menu.Item>
								</>
							)}
						</>
					)}
				</Menu.SubMenu>
			</Menu>
		</>
	);
}
